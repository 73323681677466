<template>
  <v-data-table :headers="headerComputed" :items="desserts" sort-by="no" dense>
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn color="success" dark rounded :to="{ name: 'Create Bank Transfer' }">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu offset-y :close-on-content-click="false" allow-overflow max-height="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <v-list dense flat>
            <v-list-item v-for="(header, i) in headers" :key="i">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    v-model="headerInitTemp"
                    :input-value="active"
                    :value="header"
                    @change="onInputSelect(header)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ header.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ desserts.map(x => x.number).indexOf(item.number) + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Number",
        value: "number",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Date",
        value: "date",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Bank(Outgoing)",
        value: "bankOut",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Amount(Outgoing)",
        value: "amountOut",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Rate(Outgoing)",
        value: "rateOut",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Bank(Incoming)",
        value: "bankIn",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "Amount(Incoming)",
        value: "amountIn",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 9,
        text: "Rate(Incoming)",
        value: "rateIn",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "Description",
        value: "desc",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "To Branch",
        value: "toBranch",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 12,
        text: "Total Incoming",
        value: "totalIn",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 13,
        text: "Branch",
        value: "branch",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 14,
        text: "Data Creator",
        value: "dataCreator",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 15,
        text: "Total Outgoing",
        value: "totalOut",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    headerInit: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Number",
        value: "number",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Date",
        value: "date",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Bank(Outgoing)",
        value: "bankOut",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Bank(Incoming)",
        value: "bankIn",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "Description",
        value: "desc",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 12,
        text: "Total Incoming",
        value: "totalIn",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 15,
        text: "Total Outgoing",
        value: "totalOut",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    desserts: [],
    headerInitTemp: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Number",
        value: "number",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Date",
        value: "date",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Bank(Outgoing)",
        value: "bankOut",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Bank(Incoming)",
        value: "bankIn",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "Description",
        value: "desc",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 12,
        text: "Total Incoming",
        value: "totalIn",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 15,
        text: "Total Outgoing",
        value: "totalOut",
        sortable: false,
        align: "end",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  computed: {
    headerComputed() {
      return this.headerInit;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.desserts = [
        {
          number: "BT.2020.11.0001",
          date: "05/11/2020",
          bankOut: "Bank BCA IDR Jakarta",
          bankIn: "Bank BCA IDR Surabaya",
          desc: "",
          totalIn: 2612500,
          totalOut: 2612,
        },
        {
          number: "BT.2020.11.0002",
          date: "05/11/2020",
          bankOut: "Bank BCA IDR Jakarta",
          bankIn: "Bank BCA IDR Surabaya",
          desc: "",
          totalIn: 2612500,
          totalOut: 2612,
        },
        {
          number: "BT.2020.11.0003",
          date: "05/11/2020",
          bankOut: "Bank BCA IDR Jakarta",
          bankIn: "Bank BCA IDR Surabaya",
          desc: "",
          totalIn: 2612500,
          totalOut: 2612,
        },
      ];
    },
    onInputSelect(oldItem) {
      if (!this.headerInit.some(val => val.id === oldItem.id)) {
        for (let index = 0; index < this.headerInit.length; index++) {
          if (oldItem.id < this.headerInit[index].id) {
            this.headerInit.splice(index, 0, oldItem);
            break;
          } else if (this.headerInit[this.headerInit.length - 1].id < oldItem.id) {
            this.headerInit.splice(this.headerInit.length, 0, oldItem);
            break;
          }
        }
      } else {
        this.headerInit.splice(
          this.headerInit.findIndex(val => val.id === oldItem.id),
          1
        );
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
